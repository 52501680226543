import React, { useEffect, useState, createContext, useContext } from 'react'
import { useLocation } from 'react-router-dom'

import { useGlobalState } from './state'

const toObject = ([key, val]) => ({
  [key]: val
})

const assign = (first, second) => ({
  ...first,
  ...second
})

export const initialRenderDT = {
  AMVIsVisible: {
    value: false,
    drawer: false
  },
  gridIsVisible: {
    value: true,
    drawer: false
  },
  AMVInfoIsVisible: {
    value: false,
    drawer: true
  },
  AMVInfoEditIsVisible: {
    value: false,
    drawer: true
  },
  sortableAMVFieldsIsVisible: {
    value: false,
    drawer: true
  },
  filterableAMVFieldsIsVisible: {
    value: false,
    drawer: true
  },
  AMVFieldValuesToFilterByIsVisible: {
    value: true,
    drawer: true
  }
}

export const initialRenderDTMobile = {
  AMVIsVisible: {
    value: false,
    drawer: false
  },
  gridIsVisible: {
    value: true,
    drawer: false
  },
  AMVInfoIsVisible: {
    value: false,
    drawer: true
  },
  AMVInfoEditIsVisible: {
    value: false,
    drawer: true
  },
  sortableAMVFieldsIsVisible: {
    value: false,
    drawer: true
  },
  filterableAMVFieldsIsVisible: {
    value: false,
    drawer: true
  },
  AMVFieldValuesToFilterByIsVisible: {
    value: false,
    drawer: true
  }
}

const RenderDTContext = createContext()

export const useRenderDT = () => useContext(RenderDTContext)

export const RenderDTProvider = ({ children }) => {
  const { renderDTFromServer } = useGlobalState()
  const [drawerIsOpen, setDrawerIsOpen] = useState(false)

  const initial = {
    AMVIsVisible: {
      value: false,
      drawer: false
    },
    gridIsVisible: {
      value: true,
      drawer: false
    },
    AMVInfoIsVisible: {
      value: false,
      drawer: true
    },
    AMVInfoEditIsVisible: {
      value: false,
      drawer: true
    },
    sortableAMVFieldsIsVisible: {
      value: false,
      drawer: true
    },
    filterableAMVFieldsIsVisible: {
      value: false,
      drawer: true
    },
    AMVFieldValuesToFilterByIsVisible: {
      value: false,
      drawer: true
    }
  }

  const realRenderDT = (process.env.SERVER ? renderDTFromServer : window.renderDT) || initial

  const [renderDT, setRenderDT] = useState(realRenderDT)

  const loca = useLocation()

  const toggleRender = (onlyKey, value) => {
    setRenderDT(renderDT => {
      const onlyKeyVal = renderDT[onlyKey]
      const renderDTArr = Object.entries(renderDT)
      const newState = {
        ...renderDT,
        ...({
          ...renderDTArr
            .filter(([_, val]) => val.drawer === onlyKeyVal.drawer)
            .map(([key, val]) => [key, { drawer: val.drawer, value: false }])
            .map(toObject)
            .reduce(assign),
          [onlyKey]: { ...onlyKeyVal, value: typeof value === 'boolean' ? value : !onlyKeyVal.value }
        })
      }

      return newState
    })
  }

  useEffect(() => {
    const arr = loca.pathname.split('/').filter(Boolean)
    const id = arr.length > 1 && arr[arr.length - 1]
    const search = new URLSearchParams(loca.search)

    if (id || (!process.env.SERVER && !id && window.selectedAMV)) {
      toggleRender('AMVIsVisible', true)

      if (search.has('edit')) {
        toggleRender('AMVInfoEditIsVisible', true)
      }
    }

    if (loca.pathname.includes('library') && !id && !search.has('fieldName')) {
      toggleRender('gridIsVisible', true)
      toggleRender('AMVInfoIsVisible', false)
    }
  }, [loca.pathname, loca.search])

  useEffect(() => {
    const newDrawerValue = Object.values(renderDT)
      .filter(({ drawer }) => drawer)
      .map(({ value }) => value)
      .some(Boolean)

    setDrawerIsOpen(newDrawerValue)
  }, [renderDT])

  return (
    <RenderDTContext.Provider value={{ renderDT, drawerIsOpen, toggleRender }}>
      {children}
    </RenderDTContext.Provider>
  )
}

import React, { createContext, useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import { useSearchParams } from 'react-router-dom'

import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

// import { useAPI } from '../context/useAPI'

const StateContext = createContext()

export const useGlobalState = () => useContext(StateContext)

export const GlobalStateProvider = ({ AMVs = [], renderDT: renderDTFromServer, selectedAMV, jwtMock, children }) => {
  const realSelectedAMV = (!process.env.SERVER && window.selectedAMV) || selectedAMV
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [searchParams] = useSearchParams()

  console.log('realSelectedAMV: ', realSelectedAMV)

  const [globalState, setGlobalState] = useState({
    jwtMock,
    AMVs: (!process.env.SERVER && window?.AMVs) || AMVs,
    showOnlyMyAMVs: false,
    selectedAMV: realSelectedAMV,
    sortAscendingOrder: false,
    renderDTFromServer,
    setShowSnackbar,
    sortableAMVFields: [
      { value: 'youtubeViews', text: 'Youtube view count' },
      { value: 'additionDate', text: 'Addition Date' },
      { value: 'youtubeUploadDate', text: 'Youtube upload date' },
      { value: 'duration', text: 'Duration' }
    ],
    AMVFieldToSortBy: 'youtubeViews',
    sortingDirection: 'desc',

    filterableAMVFields: [
      { value: 'animeTitle', text: 'Anime' },
      { value: 'artist', text: 'Artist' },
      { value: 'song', text: 'Song' },
      { value: 'creator', text: 'Creator' }
    ],
    AMVFieldToFilterBy: 'animeTitle',
    AMVFieldValuesToFilterBy: [],

    AMVFieldValueToFilterBy: null
  })

  const addAMV = (newAMV) => {
    setGlobalState({ ...globalState, AMVs: [...globalState.AMVs, newAMV] })
  }

  const removeSelectedAMV = () => {
    const AMVs = globalState.AMVs.filter(AMV => AMV.id !== globalState.selectedAMV.id)
    return ({
      ...globalState,
      AMVs
    })
  }

  const setAMVFieldValue = AMVFieldValueToFilterBy => setGlobalState(state => ({
    ...state,
    AMVFieldValueToFilterBy: AMVFieldValueToFilterBy === globalState.AMVFieldValueToFilterBy ? null : AMVFieldValueToFilterBy
  }))

  const setAMVFilterField = AMVFieldToFilterBy => setGlobalState({
    ...globalState,
    AMVFieldToFilterBy,
    AMVFieldValueToFilterBy: null
  })

  const searchParamsObj = Object.fromEntries(searchParams)

  useEffect(() => {
    if (searchParamsObj.fieldValue && globalState.AMVFieldValueToFilterBy !== searchParamsObj.fieldValue) {
      setAMVFieldValue(searchParamsObj.fieldValue)
    } else {
      setAMVFieldValue(searchParamsObj.fieldValue)
    }

    if (searchParamsObj.fieldName && globalState.AMVFieldToFilterBy !== searchParamsObj.fieldName) {
      setAMVFilterField(searchParamsObj.fieldValue)
    }
  }, [searchParamsObj.fieldValue, searchParamsObj.fieldName])

  const setAMVSortField = AMVFieldToSortBy => setGlobalState({
    ...globalState,
    AMVFieldToSortBy: AMVFieldToSortBy === globalState.AMVFieldToSortBy ? null : AMVFieldToSortBy
  })

  const selectAMV = id => {
    const selectedAMV = _.isObject(id) ? id : globalState.AMVs.find(amv => amv.id === id)
    setGlobalState(notStaleState => ({
      ...notStaleState,
      selectedAMV
    }))
  }

  const setAMVs = (AMVs) => {
    setGlobalState(state => ({
      ...state,
      AMVs
    }))
  }

  const appendAMVs = (AMVs) => {
    setGlobalState({
      ...globalState,
      AMVs: [...globalState.AMVs, ...AMVs]
    })
  }

  const setAMVFieldValuesToFilterBy = (AMVFieldValuesToFilterBy) => {
    setGlobalState(state => ({
      ...state,
      AMVFieldValuesToFilterBy
    }))
  }

  const toggleMyAMVs = () => setGlobalState({ ...globalState, showOnlyMyAMVs: !globalState.showOnlyMyAMVs })
  const toggleSortingDirection = () => setGlobalState(state => ({ ...state, sortingDirection: state.sortingDirection === 'asc' ? 'desc' : 'asc' }))

  const value = {
    ...globalState,
    appendAMVs,
    setGlobalState,
    setAMVFieldValuesToFilterBy,
    AMVFieldValuesToFilterBy: globalState.AMVFieldValuesToFilterBy,
    addAMV,
    removeSelectedAMV,
    setAMVFieldValue,
    setAMVFilterField,
    setAMVSortField,
    selectAMV,
    setAMVs,
    toggleMyAMVs,
    toggleSortingDirection
  }

  return (
    <StateContext.Provider value={value}>
      {children}
      <Snackbar open={Boolean(showSnackbar)} autoHideDuration={60000000} onClose={() => setShowSnackbar(false)}>
        <Alert
          onClose={() => setShowSnackbar(false)}
          severity={showSnackbar.error ? 'error' : 'success'}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {showSnackbar.msg}
        </Alert>
      </Snackbar>
    </StateContext.Provider>
  )
}

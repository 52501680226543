import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { omit } from 'lodash'

import { MenuItem } from '../MenuItem'

import { useGlobalState } from '../../../../context/state'
import { useRenderDT } from '../../../../context/render-tree'

export const FieldValueToFilterByMenu = () => {
  const { AMVFieldValuesToFilterBy, AMVFieldValueToFilterBy } =
    useGlobalState()
  const { toggleRender } = useRenderDT()
  const [searchParams, setSearchParams] = useSearchParams()

  const searchParamsObj = Object.fromEntries(searchParams)

  return (
    <div>
      {AMVFieldValuesToFilterBy.map((filter) => (
        <MenuItem
          selected={filter.value === AMVFieldValueToFilterBy}
          key={filter.value}
          onClick={() => {
            setSearchParams(
              omit(
                {
                  ...searchParamsObj,
                  fieldValue:
                    searchParamsObj.fieldValue === filter.value
                      ? null
                      : filter.value
                },
                searchParamsObj.fieldValue === filter.value ? 'fieldValue' : ''
              )
            )
            toggleRender('AMVFieldValuesToFilterByIsVisible', false)
          }}
          secondaryText={[{ text: `${filter.AMVCount} AMV`, msg: 'AMV Count' }]}
          primaryText={filter.value}
          multiSelect
        />
      ))}
    </div>
  )
}

import React from 'react'
import classNames from 'classnames'

import { useGlobalState } from '../../context/state'

import { useRenderDT } from '../../context/render-tree'

import './styles.scss'

export const AMVView = () => {
  const globalState = useGlobalState()
  const { renderDT: { AMVInfoIsVisible } } = useRenderDT()

  const { selectedAMV: { url } } = globalState

  return (
    <div className='amv-view-container'>
      <video className={classNames('video-view', { 'video-center': !AMVInfoIsVisible.value })} controls preload="metadata">
        <source src={`${url}#t=0.1`} type="video/mp4" />
      </video>
    </div>
  )
}
